import { Icon, IconProps } from './Icon';
import { FilePng } from '@styled-icons/boxicons-solid';
import { FilePdf } from '@styled-icons/boxicons-solid';
import { FileWord } from '@styled-icons/fa-regular';
import { FileGif } from '@styled-icons/boxicons-solid';
import { FileJpg } from '@styled-icons/boxicons-solid';
import { FileImage } from '@styled-icons/boxicons-solid';
import { File } from '@styled-icons/boxicons-solid/File';

export function FileIcon({ fontSize, ...props }: IconProps) {
  return (
    <Icon fontSize={fontSize} {...props}>
      <File {...props} />
    </Icon>
  );
}

export function FileImageIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FileImage {...props} />
    </Icon>
  );
}

export function FileJpgIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FileJpg {...props} />
    </Icon>
  );
}

export function FileGifIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FileGif {...props} />
    </Icon>
  );
}

export function FileWordIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FileWord {...props} />
    </Icon>
  );
}

export function FilePdfIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FilePdf {...props} />
    </Icon>
  );
}

export function FilePngIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <FilePng {...props} />
    </Icon>
  );
}
