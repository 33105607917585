import { Download } from '@styled-icons/feather';
import { Icon, IconProps } from './Icon';

export function DownloadIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Download />
    </Icon>
  );
}
